
body {
  color: #000;
  font-size: 20px;
  line-height: 30px;
  font-family: 'Montserrat', sans-serif;
}

h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 38px;
  line-height: 44px;
  font-weight: 700;
}

h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 40px;
  line-height: 50px;
  font-weight: 500;
}

h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 24px;
  line-height: 33.6px;
  font-weight: 500;
}

h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.main-block-brand {
  min-height: 100vh;
  background: #212324;
  position: relative;
}

.header-block-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.header-block-info .common-container-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-block-info__content {
  display: flex;
}

.header-block-info__btn-1 {
  padding: 14px 32px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.header-block-info__btn-1 span {
  color: #53BEF4;
}

.header-block-info__btn-1 {
  border: 1px solid #474B51;
  border-radius: 50px;
}

.header-block-info__btn-2 span {
  color: #BA56FA;
}

.header-block-info__btn-2 {
  padding: 14px 32px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  border: 1px solid #474B51;
  border-radius: 50px;
  margin-left: 24px;
  cursor: pointer;
  transition: all 0.4s;
}

.header-block-info__btn-1 {
  cursor: pointer;
  transition: all 0.4s;
}

.main-block-brand__container {
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
  padding-top: 300px;
}

.title-page {
  font-weight: 800;
  font-size: 80px;
  line-height: 93px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: white;
}

.subtitle-page {
  font-weight: 500;
  font-size: 48px;
  line-height: 59px;
  text-align: right;
  letter-spacing: 0.04em;
  color: #FFFFFF;
}

.title-info {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #9A9DB0;
  position: absolute;
  bottom: 92px;
}

.title-info span{
  color: white;
}

.header-block-info {
  padding: 34px 0 0;
}

.fixed {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
}

.common-container-header {
  width: 100%;
  margin: 0 auto;
}

.header-fixed .common-container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-fixed {
  transition: all 0.4s;
  top: -40px;
  position: fixed;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
}

.fixed {
  z-index: 1;
  visibility: visible;
  opacity: 1;
}

.header-fixed.fixed {
  top: 0;
}

.header-fixed__btn-1 {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #2C2E30;
  cursor: pointer;
  padding: 10px 24px;
  border: 1px solid #E6E6EA;
  border-radius: 50px;
}

.header-fixed__btn-2 {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #2C2E30;
  cursor: pointer;
  padding: 10px 24px;
  border: 1px solid #E6E6EA;
  border-radius: 50px;
  margin-left: 24px;
}

.header-fixed__info {
  display: flex;
}

.header-fixed__logo {
  height: 52px;
}

.header-fixed {
  left: 0;
  width: 100%;
  padding: 10px 0;
  background: white;
}

.header-fixed__btn-1 span {
  color: #72758F;
}

.header-fixed__btn-2 span {
  color: #72758F;
}

.block-info {
  display: flex;
}

.block-info__left-white {
  width: 50%;
  padding: 100px 130px 100px 120px;
}

.block-info__right-black {
  width: 50%;
  background: #3E4348;
  padding: 100px 120px 100px 130px;
}

.block-info svg {
  margin-bottom: 10px;
}

.block-info__right-black-text {
  font-weight: 400;
  font-size: 24px;
  line-height: 42px;
  color: #FFFFFF;
}

.block-info__tabs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.block-info__tab {
  padding: 2px 20px;
  background: #212324;
  font-weight: 600;
  font-size: 26px;
  line-height: 42px;
  color: white;
}

.block-info__tabs {
  gap: 8px;
  margin: 12px 0;
}

.block-info__tab span {
  color: #575D63;
}

.block-info__left-white-text {
  font-weight: 400;
  font-size: 24px;
  line-height: 42px;
  color: #2C2E30;
}

.block-info__left-white-text a {
  color: #72758F;
  text-decoration: auto;
}

.block-info__left-white-text span {
  font-weight: 700;
  color: #35414C;
}

.animation-up-down-negative svg{
  animation-name: up-down-negative;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform: translateY(0);

}

.animation-up-down-positive svg{
  animation-name: up-down-positive;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform: translateY(0);
}

@keyframes up-down-negative {
  0% {
    transform: translateY(0);
  }
  25%  {
    transform: translateY(-10px);
  }
  50%  {
    transform: translateY(-30px);
  }
  75%  {
    transform: translateY(-10px);
  }
  100%   {
    transform: translateY(0px);
  }
}

@keyframes up-down-positive {
  0% {
    transform: translateY(0);
  }
  25%  {
    transform: translateY(10px);
  }
  50%  {
    transform: translateY(30px);
  }
  75%  {
    transform: translateY(10px);
  }
  100%   {
    transform: translateY(0px);
  }
}


.social-block {
  background: #F6F6F9;
  min-height: 740px;
  position: relative;
  justify-content: center;
}

.social-block__icon {
  position: absolute !important;
  width: 100%;
  height: 100%;
}

.page-conteiner {
  position: relative;
  overflow: hidden !important;
  width: 100%;
  overflow-x: hidden !important;
}

.social-block__center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.social-block__main-img {
  position: relative;
  width: 100%;
  text-align: center;
}

.social-block__title {
  position: relative;
  top: 50px;
}

.social-block__title::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='27' viewBox='0 0 42 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 12.011H36.1L25.8 2.18382L27.9 0L42 13.5L27.9 27L25.8 24.8162L36.1 14.989H0V12.011Z' fill='%23E0E1EC'/%3E%3C/svg%3E");
  width: 42px;
  display: block;
  height: 27px;
}

.social-block__title:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='27' viewBox='0 0 42 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M42 12.011H5.9L16.2 2.18382L14.1 0L0 13.5L14.1 27L16.2 24.8162L5.9 14.989H42V12.011Z' fill='%23E0E1EC'/%3E%3C/svg%3E");
  width: 42px;
  display: block;
  height: 27px;
}

.coffee {
  position: absolute;
  right: -110px;
  bottom: 0;
}

.social-block__icon {
  width: 100%;
  height: 100%;
  text-align: center;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-block__icon div {
  position: relative !important;
  height: 0;
}

.social-block__main-img {
  top: -11px;
}

.social-block__center {
  padding-top: 55px;
}

.social-block__1 svg{
  position: relative ;
  top: -255px ;
}

.social-block__2 svg{
  position: relative ;
  top: -190px ;
}

.social-block__3 svg{
  position: relative ;
  top: -82px ;
}

.social-block__4 svg{
  position: relative ;
  top: -36px ;
}

.social-block__5 svg{
  position: relative ;
  top: -237px ;
}

.social-block__6 svg{
  position: relative ;
  top: -235px ;
}

.social-block__7 svg{
  position: relative ;
  top: -80px ;
}

.social-block__8 svg{
  position: relative ;
  top: -13px ;
}

.social-block__1 svg {
  left: -72px ;
  animation-duration: 2s;
}

.social-block__2 svg {
  left: -25px ;
  animation-duration: 2.2s;
}

.social-block__3 svg {
  left: -369px ;
  animation-duration: 1.6s;
}

.social-block__4 svg {
  left: -299px ;
  animation-duration: 2.4s;
}

.social-block__6 svg {
  left: 368px ;
  animation-duration: 2.6s;
}

.social-block__7 svg {
  left: 29px ;
  animation-duration: 2.1s;
}

.social-block__8 svg {
  left: 92px ;
  animation-duration: 2.8s;
}

.social-block__5 svg {
  left: 250px;
  animation-duration: 2s;
}

.info-company {
  max-width: 1180px;
  width: 100%;
  margin: 100px auto;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.info-company__quest {
  min-width: 380px;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: #2C2E30;
}

.info-company__quest-text.fixed-bottom {
  position: absolute;
  bottom: 0;
  left: 0 !important;
}

.info-company__quest-text.fixed {
  top: 60px;
}

.info-company__quest {
  position: relative;
}

.info-company__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 42px;
  color: #2C2E30;
  margin-bottom: 44px;
}

.info-company__title a {
  color: #72758F;
  text-decoration: auto;
}

.info-company__info-title-img {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 14px;
}

.info-company__info-block {
  margin-bottom: 41px;
}

.info-company__info-title {
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  color: #2C2E30;
}

.info-company__info-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.04em;
  color: #2C2E30;
}

.footer {
  background: #2C2E30;
  padding-top: 100px;
}

.info-company__info .info-company__info-block:nth-last-child(1) {
  margin-bottom: 0;
}

.footer__container {
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.footer__left-info {
  min-width: 380px;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.footer__info-text {
  font-weight: 400;
  font-size: 24px;
  line-height: 42px;
  color: #E0E1EC;
}

.footer__info-text span {
  font-weight: 700;
  color: white;
}

.footer__btn-container {
  display: flex;
  gap: 24px;
}

.footer__btn-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 78px;
  background: linear-gradient(90deg, #52C1FF 0%, #1796F1 100%);
  border-radius: 50px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  gap: 6px;
}

.footer__btn-1 span {
  font-weight: 700;
}

.footer__btn-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;
  width: 331px;
  height: 78px;
  background: linear-gradient(90deg, #7B00EE 0%, #6B00EF 100%);
  border-radius: 50px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  gap: 6px;
}

.footer__btn-2 span {
  font-weight: 700;
}

.footer__info-text {
  margin-bottom: 60px;
}

.social-block__title::before {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: translateY(-25px);
}

.social-block__title::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform: translateY(-25px);
}

.content-time .time-location.clock {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  box-sizing: border-box;
  background: rgba(114, 117, 143, 0.15);
}
.content-time .time-location.clock#okinawa, .content-time .time-location.clock#amalfi {
  width: 48px;
  height: 48px;
}
.content-time .time-location.clock#okinawa:before, .content-time .time-location.clock#amalfi:before {
  border-width: 2px;
}
.content-time .time-location.clock#okinawa .hand, .content-time .time-location.clock#amalfi .hand {
  height: 2px;
  top: 20px;
  transform-origin: right 1.5px;
}
.content-time .time-location.clock#okinawa .hand.seconds, .content-time .time-location.clock#amalfi .hand.seconds {
  display: none;
}
.content-time .time-location.clock:before {
  content-time: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 6px solid #FFF;
  border-radius: 100%;
  box-sizing: border-box;
}

.content-time .time-location.clock .hand {
  height: 2px;
  position: absolute;
  top: 24px;
  background-color: #72758F;
  border-radius: 1px 0% 0% 1px;
  transform-origin: right 1px;
  transition: all 0.05s cubic-bezier(0, 0, 0.52, 2.51) 0s;
}
.content-time .time-location.clock .hand.seconds {
  width: 20px;
  left: 5%;
  opacity: 0.25;
}
.content-time .time-location.clock .hand.minutes {
  width: 18px;
  left: 13%;
  opacity: 1;
}
.content-time .time-location.clock .hand.hours {
  width: 12px;
  left: 25%;
  opacity: 1;
}
.content-time .time-location.clock .hand-cap {
  width: 6px;
  height: 6px;
  position: absolute;
  top: 22px;
  left: 21px;
  background-color: #72758F;
  border-radius: 100%;
}
.content-time .time-location label {
  display: block;
  width: 100%;
  position: absolute;
  top: -24px;
  left: 0;
  font-family: sans-serif;
  font-weight: 200;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 4px;
  color: #72758F;
  opacity: 1;
}

.footer__btn-container {
  margin-bottom: 100px;
}

.content-time .time-location.clock .hand.seconds {
  display: none;
}

.content-time {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.footer__right-info {
  width: 100%;
  max-width: 780px;
}

.time-location__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  margin: 8px 0;
}

.time-location__subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #E0E1EC;
}

.footer__contact {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 30px;
}

.footer__contact-link{
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  text-decoration: auto;
}

div.footer__contact:nth-child(1) > svg:nth-child(1) {
  opacity: 0.6;
}

div.footer__contact:nth-child(1) {
  margin-top: 20px;
}

.footer__time {
  margin-bottom: 80px;
}

.footer__copy {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #E0E1EC;
  margin-bottom: 60px;
}

.company-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #E0E1EC;
  text-decoration: auto;
}

.footer__contact {
  margin-bottom: 35px;
}

.title-page{
  animation: gradient 4s linear infinite;
  background: -webkit-linear-gradient(15deg, #69EBF3, #3C91F5 , #69EBF3, #3C91F5 );
  background-size: 300%;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

@keyframes gradient {
  from{
    background-position: 100% 0%;
  }
  to{
    background-position: 0% 0%;
  }
}

.common-container-header {
  max-width: 1400px;
  padding: 0 20px;
}

.title-page-sub-1{
  background: -webkit-linear-gradient(15deg, #8100BE, #4204C5 , #8100BE, #4204C5 );
  animation: gradient 0s linear infinite;
}

.title-page-sub-2{
  background: -webkit-linear-gradient(15deg, #8100BE, #4204C5 , #8100BE, #4204C5 );
  animation: gradient 0s linear infinite;
}

.title-page-sub-3{
  background: -webkit-linear-gradient(15deg, #B80084, #FCFF6C , #B80084, #FCFF6C );
  animation: gradient 0s linear infinite;
}

.title-page-sub-4{
  background: -webkit-linear-gradient(15deg, #B80084, #FCFF6C , #B80084, #FCFF6C );
  animation: gradient 0s linear infinite;
}

.animation-title {
  position: relative;
}

#title-scene {
  position: absolute !important;
}

.main-title{
  position: relative;
  z-index: 1;
}

// модалка
.form__group {
  position: relative;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  background: transparent;
  transition: border-color 0.4s;
}
.form__field::placeholder {
  color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
  font-size: 1.3rem;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.4s;
  color: #9b9b9b;
}

.form__field:focus {
  border-width: 2px;
  border-image: linear-gradient(to right, #72758f, #CFCFCF);
  border-image-slice: 1;
  transition: 0.4s;
}
.form__field:focus ~ .form__label {
  position: absolute;
  top: -14px;
  display: block;
  transition: 0.4s;
  color: #72758f;
  font-weight: 600;
}

.form__field:required, .form__field:invalid {
  box-shadow: none;
}

.popup {
  width: 100%;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #CFCFCF;
  transition: all 0.6s;
  transform: translateY(-30px);
  opacity: 0;
}

.visible-modal{
  transform: translateY(0);
  opacity: 1;
  z-index: 100;
}

.popup__title {
  font-weight: 700;
  font-size: 44px;
  line-height: 56px;
  color: #2C2E30;
}

.popup__title span {
  color: #72758F;
}

.popup__title {
  margin-bottom: 50px;
}

.popup__close {
  position: absolute;
  top: 65px;
  right: 28px;
}

.body-hidden-scroll {
  overflow: hidden;
}

.popup__container {
  width: 100%;
  max-width: 580px;
}

.form__group {
  margin-bottom: 45px;
}

.form__field:placeholder-shown ~ .form__label {
  top: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: #72758F;
}

.form__label {
  top: -14px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.06em;
  color: #72758F;
}

.form__field {
  border-bottom: 2px solid rgba(179, 181, 196, 0.4);
  padding: 0 0 8px;
  color: #2C2E30;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.04em;
}

.popup__form-submit {
  margin-top: 50px;
}

.popup__form-submit input {
  width: 200px;
  height: 60px;
  background: #2C2E30;
  border: 1px solid #2C2E30;
  box-shadow: 0px 8px 40px rgba(114, 138, 175, 0.24);
  border-radius: 50px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #FFFFFF;
  text-align: center;
  margin-right: 40px;
  cursor: pointer;
}

.popup__form-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #72758F;
  text-decoration: auto;
}

.popup__form-link span {
  color: #2C2E30;
}

.popup__form-submit input:hover {
  background: #3E4348;
}

.popup__form-submit input {
  transition: all 0.6s;
}

.popup__close path {
  transition: 0.4s;
}

.popup__close:hover path {
  fill: #2C2E30;
}

.popup {
  background: #F6F6F9;
}

.header-block-info__btn-1:hover {
  border: 1px solid transparent;
  background: linear-gradient(90deg, #69F3DA 0%, #3C91F5 100%);
  box-shadow: 0px 8px 40px rgba(83, 191, 244, 0.15);
}

.header-block-info__btn-1:hover span {
  color: white;
}

.header-block-info__btn-1,
.header-block-info__btn-2{
  transition: all 0.4s;
  background: transparent;
}

.header-block-info__btn-1 span,
.header-block-info__btn-2 span{
  font-weight: 700;
}

.header-block-info__btn-2:hover {
  border: 1px solid transparent;
  background: linear-gradient(90deg, #BD00FF 0%, #7203FE 100%);
  box-shadow: 0px 8px 40px rgba(121, 81, 235, 0.15);
}

.header-block-info__btn-2:hover span {
  color: white;
}

.footer__btn-1 {
  cursor: pointer;
  transition: all 0.4s;
}

.footer__btn-2 {
  cursor: pointer;
  transition: all 0.4s;
}

.company-link {
  transition: all 0.4s;
}

.header-fixed__btn-1 span, .header-fixed__btn-2 span {
  font-weight: 700;
}

.header-fixed__btn-1 {
  transition: all 0.4s;
}

.header-fixed__btn-2 {
  transition: all 0.4s;
}

.block-info__left-white-text a {
  transition: all 0.4s;
}

.info-company__title a {
  transition: all 0.4s;
}

.footer__btn-1:hover {
  background: linear-gradient(90deg, #69F3DA 0%, #3C91F5 100%);
  box-shadow: 0px 0px 40px rgba(26, 133, 239, 0.4);
}

.footer__btn-2:hover {
  background: linear-gradient(90deg, #BD00FF 0%, #7203FE 100%);
  box-shadow: 0px 0px 40px rgba(104, 48, 199, 0.4);
}

.company-link:hover {
  color: #53BEF4;
}

.footer__contact a {
  transition: all 0.4s;
}

.footer__contact:hover a {
  color: #53BEF4;
}

.header-fixed__btn-1:hover {
  box-shadow: 0px 8px 40px rgba(83, 191, 244, 0.15);
  background: #2C2E30;
  color: #FFFFFF;
  border: 1px solid transparent;
}

.header-fixed__btn-1:hover span {
  color: #53BEF4;
}

.header-fixed__btn-2:hover {
  border: 1px solid transparent;
  box-shadow: 0px 8px 40px rgba(83, 191, 244, 0.15);
  background: #2C2E30;
  color: #FFF;
}

.header-fixed__btn-2:hover span {
  color: #BA56FA;
}

.block-info__left-white-text a:hover {
  color: #35414C;
}

.info-company__title a:hover {
  color: #2C2E30;
}


/* style.min.css | http://localhost:3000/css/style.min.css */

.title-info {
  z-index: 2;
  text-decoration: auto;
}

.title-info span {
  transition: all 0.4s;
  position: relative;
  top: 0;
}

.title-info:hover span {
  background: linear-gradient(90deg, #69F3DA 0%, #3C91F5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  top: 6px;
}

.title-info:hover svg {
  opacity: 1;
  position: relative;
  visibility: visible;
  top: 10px;
}

.title-info:hover svg path {
  fill: #3E95F4;
}

.title-info svg {
  transition: all 0.4s;
  position: relative;
  top: 0;
}

.title-info svg path {
  transition: all 0.4s;
}

.form__field {
  outline: none !important;
  border-radius: 0 !important;
}



















@media( min-width: 1800px ){

  .common-container-header {
    max-width: 1760px;
  }

  .main-block-brand__container {
    max-width: 1550px;
    padding-top: 340px;
  }

  .title-page {
    font-size: 100px;
    line-height: 130px;
  }

  .block-info__right-black {
    padding: 100px 250px 100px 200px;
  }

  .block-info__left-white {
    padding: 100px 200px 100px 250px;
  }

  .info-company {
    max-width: 1420px;
    margin: 140px auto;
  }

  .footer__container {
    max-width: 1420px;
  }

  .footer__right-info {
    max-width: 940px;
  }

  .social-block__1 svg {
    top: -305px;
    left: -115px;
  }

  .social-block__2 svg {
    top: -255px;
    left: -45px;
  }

  .social-block__3 svg {
    top: -115px;
    left: -465px;
  }

  .social-block__4 svg {
    top: -65px;
    left: -395px;
  }

  .social-block__5 svg {
    left: 345px;
  }

  .social-block__5 svg {
    top: -290px;
  }

  .social-block__6 svg {
    top: -275px;
    left: 420px;
  }

  .social-block__8 svg {
    top: 0px;
    left: 110px;
  }


}

@media( max-width: 1280px ){

  .header-block-info {
    padding: 26px 0 0;
  }

  .header-fixed.fixed {
    z-index: 10;
  }

  .info-company__quest-text.fixed {
    top: 0;
  }

  .common-container-header {
    max-width: 1240px;
    padding: 0 40px;
  }

  .title-page {
    font-size: 112px;
    line-height: 134px;
  }

  .subtitle-page {
    font-size: 40px;
    line-height: 49px;
  }

  .title-info {
    display: block;
    bottom: 52px;
    font-size: 20px;
    line-height: 32px;
  }

  .main-block-brand__container {
    max-width: 1084px;
  }

  .block-info__left-white {
    padding: 80px 98px;
  }

  .block-info__right-black {
    padding: 80px 98px;
  }

  .social-block__1 svg {
    width: 104px;
    height: 104px;
  }

  .social-block__2 svg {
    width: 80px;
    height: 80px;
  }

  .social-block__3 svg {
    left: -335px;
    width: 80px;
    height: 80px;
  }

  .social-block__4 svg {
    width: 104px;
    height: 104px;
  }

  .social-block__5 svg {
    left: 307px;
    width: 80px;
    height: 80px;
  }

  .social-block__5 svg {
    top: -175px;
  }

  .social-block__6 svg {
    top: -275px;
    left: 412px;
    width: 104px;
    height: 104px;
  }

  .social-block__7 svg {
    top: -40px;
    left: 155px;
    width: 104px;
    height: 104px;
  }

  .social-block__8 svg {
    top: -79px;
    left: 185px;
    width: 80px;
    height: 80px;
  }

  .info-company {
    max-width: 1044px;
    padding: 0 20px;
  }

  .footer__container {
    max-width: 1040px;
    padding: 0 20px;
  }



}

@media( max-width: 1180px ){

  .title-page {
    font-size: 90px;
    line-height: 110px;
  }

  .subtitle-page {
    font-size: 34px;
    line-height: 41px;
  }

  .main-block-brand__container {
    max-width: 870px;
  }

  .block-info__left-white, .block-info__right-black {
    padding: 64px 78px;
  }

  .social-block__3 svg {
    left: -233px;
  }

  .social-block__5 svg {
    left: 215px;
    top: -76px;
  }

  .social-block__6 svg {
    top: -230px;
    left: 245px;
  }

  .social-block__7 svg {
    top: -20px;
    left: 130px;
  }

  .social-block__8 svg {
    left: 120px;
  }

  .info-company {
    padding: 0 78px;
  }

  .footer__container {
    padding: 0 78px;
  }

  .main-block-brand__container {
    padding-top: 260px;
  }

  .block-info__left-white-text {
    font-size: 20px;
    line-height: 36px;
  }

  .block-info__right-black-text {
    font-size: 20px;
    line-height: 36px;
  }

  .block-info__tab {
    font-size: 22px;
    line-height: 36px;
  }

  .social-block {
    min-height: 566px;
  }

  .social-block__center {
    padding-top: 0;
  }

  .social-block__title::before {
    transform: translateY(-18px);
    left: -15px;
  }

  .social-block__title::after {
    right: -15px;
    transform: translateY(-18px);
  }

  .social-block__main-img {
    top: -50px;
  }

  .social-block__title {
    top: 0px;
  }

  .coffee {
    right: -80px;
  }

  .social-block__1 svg {
    top: -195px;
    left: 40px;
  }

  .social-block__2 svg {
    top: -95px;
    left: 92px;
  }

  .social-block__3 svg {
    top: -28px;
  }

  .social-block__4 svg {
    top: 21px;
    left: -185px;
  }

  .info-company {
    flex-wrap: wrap;
  }

  .info-company__title {
    margin-bottom: 41px;
    font-size: 20px;
    line-height: 32px;
  }

  .info-company__info-title {
    font-size: 24px;
    line-height: 42px;
  }

  .footer__info-text {
    font-size: 20px;
    line-height: 36px;
  }

  .footer__btn-1 {
    width: 243px;
    height: 60px;
    font-size: 15px;
    line-height: 20px;
  }

  .footer__btn-2 {
    width: 280px;
    height: 60px;
    font-size: 15px;
    line-height: 20px;
  }

  .footer__left-info {
    min-width: 276px;
  }

  .footer__contact {
    margin-bottom: 20px;
  }

  .header-block-info__btn-1 {
    padding: 14px 32px;
  }

  .header-block-info__btn-2 {
    padding: 14px 32px;
  }

  .social-block__title svg {
    width: 240px;
  }

  .enot-pc svg {
    width: 238px;
    height: 246px;
  }

  .coffee svg {
    width: 54px;
    height: 69px;
  }

  .info-company__quest-text {
    position: relative !important;
    left: 0 !important;
  }

  .header-block-info__logo svg {
    width: 280px;
  }

  div.footer__container:nth-child(1) {
    flex-wrap: wrap;
  }

  #title-scene {
    display: none;
  }

  .body-hidden-scroll {
    overflow: hidden !important;
  }

  .popup__container {
    min-height: 625px;
    position: relative;
    padding: 24px 30px 0;
  }

  .popup {
    align-items: flex-start;
    overflow-y: scroll;
    overflow: scroll;
  }

  .popup__title {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 32px;
    width: 250px;
  }

  .popup__title span {
    display: block;
  }

  .popup__close {
    top: 27px;
    right: 30px;
  }

  .form__field:placeholder-shown ~ .form__label {
    letter-spacing: .06em !important;
    font-weight: 500;
    font-size: 13px !important;
    line-height: 18px !important;
  }

  .form__field {
    padding: 0px 0 0px;
  }

  .form__group {
    margin-bottom: 45px;
  }

  .popup__form-submit {
    margin-top: 40px;
  }

  .popup__form-submit input {
    width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }

  .popup__form-link {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
  }




}

@media( max-width: 992px ){

  .header-block-info {
    padding: 24px 0 0;
  }

  .common-container-header {
    padding: 0px 34px;
  }

  .title-info {
    bottom: 0;
  }

  .social-block__1 svg {
    width: 90px;
    height: 90px;
  }

  .social-block__2 svg {
    width: 64px;
    height: 64px;
  }

  .social-block__3 svg {
    width: 64px;
    height: 64px;
  }

  .social-block__4 svg {
    width: 90px;
    height: 90px;
  }

  .social-block__5 svg {
    width: 64px;
    height: 64px;
  }

  .social-block__6 svg {
    width: 90px;
    height: 90px;
  }

  .social-block__7 svg {
    width: 90px;
    height: 90px;
  }

  .social-block__8 svg {
    top: -155px;
    width: 64px;
    height: 64px;
  }

  .main-block-brand__container {
    max-width: 676px;
    padding-top: 180px;
  }

  .title-page {
    font-size: 65px;
    line-height: 75px;
  }

  .title-page {
    margin-bottom: 60px;
  }

  .header-block-info__content {
    justify-content: center;
  }

  .subtitle-page {
    font-size: 24px;
    line-height: 29px;
    text-align: left;
    margin-bottom: 64px;
  }

  .block-info__left-white, .block-info__right-black {
    padding: 34px 40px;
  }

  .social-block {
    min-height: 560px;
  }

  .social-block__1 svg {
    top: -255px;
    left: 30px;
  }

  .social-block__2 svg {
    top: -108px;
    left: 27px;
  }

  .social-block__3 svg {
    top: -155px;
  }

  .social-block__3 svg {
    left: -190px;
  }

  .social-block__4 svg {
    top: -45px;
    left: -188px;
  }

  .social-block__5 svg {
    left: 125px;
    top: -110px;
  }

  .social-block__6 svg {
    top: -232px;
    left: 112px;
  }

  .social-block__7 svg {
    top: -38px;
    left: 55px;
  }

  .social-block__8 svg {
    left: 25px;
  }

  .coffee {
    right: -60px;
  }

  .footer__container, .info-company {
    padding: 0 34px;
  }

  .footer__left-info {
    min-width: 100%;
    display: flex;
    align-items: center;
    gap: 40px;
  }

  .main-block-brand {
    min-height: auto;
  }

  .title-info {
    position: relative;
    margin-top: 96px;
    padding-bottom: 40px;
    text-align: center;
  }

  .block-info {
    flex-wrap: wrap;
  }

  .block-info__left-white {
    width: 100%;
  }

  .block-info__right-black {
    width: 100%;
  }

  .social-block__icon div {
    transform: translate3d(0, 0, 0) !important;
  }

  .info-company {
    margin: 80px auto;
  }

  .info-company__quest {
    font-size: 20px;
    line-height: 32px;
  }

  .footer {
    padding-top: 80px;
  }

  .footer__left-info {
    font-size: 20px;
    line-height: 32px;
  }

  .footer__right-info {
    max-width: 100%;
  }

  div.footer__contact:nth-child(1) {
    margin-top: 0;
  }

  .footer__container {
    flex-wrap: wrap;
  }

  .footer__contact-link {
    color: #53BEF4;
  }

  .company-link {
    color: #53BEF4;
  }

  .footer__time {
    margin-bottom: 60px;
  }

  .common-container-header .header-block-info__content {
    display: none;
  }

  .title-info svg {
    position: relative;
    top: 5px;
    left: 5px;
  }

  .social-block__icon svg {
    animation-duration: 0s !important;
  }

  div.footer__container:nth-child(3) {
    flex-wrap: nowrap;
  }

  div.footer__container:nth-child(3) > div:nth-child(1) {
    max-width: 276px;
    min-width: 276px;
  }

  .header-fixed__logo svg {
    width: 200px;
  }

  .header-fixed__btn-1 {
    font-size: 12px;
    line-height: 18px;
    padding: 8px 12px;
  }

  .header-fixed__btn-2 {
    font-size: 12px;
    line-height: 18px;
    padding: 8px 12px;
    margin-left: 10px;
  }

  .header-block-info__btn-1, .header-block-info__btn-2 {
    padding: 19px 40px;
  }



}

@media( min-width: 992px ){
  .pc-none{
    opacity: 0;
    visibility: hidden;
  }
}

@media( max-width: 680px ){

  .main-block-brand__container {
    max-width: 320px;
    padding-top: 70px;
  }

  .title-page {
    font-size: 32px;
    line-height: 39px;
    margin-top: 25px;
  }

  .subtitle-page {
    text-align: center;
    margin-bottom: 76px;
    font-size: 24px;
    line-height: 29px;
  }

  .header-block-info {
    display: none;
  }

  .logo-mob {
    margin: 0 auto;
    display: block;
  }

  .content-time__element {
    width: 100%;
    max-width: 150px;
  }

  .header-block-info__content {
    flex-wrap: wrap;
    gap: 24px;
  }

  .header-block-info__btn-1 {
    width: 100%;
    text-align: center;
  }

  .header-block-info__btn-2 {
    width: 100%;
    margin-left: 0;
    text-align: center;
    background: linear-gradient(90deg, #BD00FF 0%, #7203FE 100%);
    box-shadow: 0px 8px 40px rgba(121, 81, 235, 0.15);
    border: 1px solid transparent;
  }

  .header-block-info__btn-1, .header-block-info__btn-2 {
    padding: 20px 40px;
    border: 1px solid transparent;
  }
  .block-info__left-white-text, .block-info__right-black-text {
    font-size: 18px;
    line-height: 32px;
  }
  .block-info__tab {
    font-size: 18px;
    line-height: 32px;
  }
  .enot-pc svg {
    width: 200px;
    height: 200px;
  }
  .social-block__main-img {
    top: -115px;
  }
  .social-block__title {
    top: -20px;
  }
  .info-company__title {
    font-size: 18px;
  }
  .info-company__info-title {
    font-size: 18px;
    line-height: 28px;
  }
  .footer__info-text {
    font-size: 18px;
    line-height: 32px;
  }
  .footer__btn-1 {
    width: 100%;
  }
  .footer__btn-2 {
    width: 100%;
  }
  .footer__contact {
    width: 100%;
  }

  .social-block__icon {
    padding-left: 10px;
    padding-right: 10px;
  }

  .header-fixed {
    display: none;
  }

  .header-block-info__btn-1 {
    background: linear-gradient(90deg, #69F3DA 0%, #3C91F5 100%);
    box-shadow: 0px 8px 40px rgba(83, 191, 244, 0.15);
    border: 1px solid transparent;
  }

  .header-block-info__btn-1 span {
    color: white;
    font-weight: 700;
  }

  .header-block-info__btn-2 span {
    color: white;
    font-weight: 700;
  }

  .title-info {
    margin-top: 74px;
  }
  .block-info__left-white, .block-info__right-black {
    padding: 40px 20px;
  }
  .social-block {
    min-height: 420px;
    height: 420px;
  }
  .social-block__1 svg {
    width: 48px;
    height: 48px;
  }
  .social-block__2 svg, .social-block__3 svg {
    width: 48px;
    height: 48px;
  }
  .social-block__4 svg {
    width: 48px;
    height: 48px;
  }
  .social-block__5 svg {
    width: 48px;
    height: 48px;
  }
  .social-block__6 svg, .social-block__7 svg {
    width: 48px;
    height: 48px;
  }
  .social-block__8 svg {
    top: 0;
    width: 48px;
    height: 48px;
  }
  .social-block__1 svg {
    top: 0;
    left: 0;
  }
  .social-block__2 svg {
    top: 0;
    left: 0;
  }
  .social-block__3 svg {
    top: 0;
    left: 0;
  }
  .social-block__4 svg {
    top: 0;
    left: 0;
  }
  .social-block__5 svg {
    left: 0;
    top: 0;
  }
  .social-block__6 svg {
    top: 0;
    left: 0;
  }
  .social-block__7 svg {
    top: 0;
    left: 0;
  }
  .social-block__8 svg {
    left: 0;
  }
  .info-company {
    margin: 50px auto;
  }
  .footer__container, .info-company {
    padding: 0 20px;
  }
  .info-company__quest {
    font-size: 16px;
    line-height: 24px;
  }
  .footer {
    padding-top: 50px;
  }
  .footer__left-info {
    gap: 0;
    font-size: 16px;
    line-height: 24px;
    flex-wrap: wrap;
  }
  div.footer__container:nth-child(3) {
    flex-wrap: wrap;
    padding-bottom: 50px;
  }
  .header-fixed__btn-1 {
    font-size: 10px;
    line-height: 14px;
    padding: 2px 10px;
    width: 100%;
    text-align: center;
  }
  .header-fixed__btn-2 {
    font-size: 10px;
    line-height: 14px;
    padding: 2px 10px;
    width: 100%;
    margin-left: 0;
    text-align: center;
  }

  .title-info {
    font-size: 16px;
    line-height: 24px;
  }

  .title-info span {
    color: #9a9db0;
  }

  .title-info {
    color: white;
  }

  .social-block__title::after {
    display: none;
  }

  .social-block__title::before {
    display: none;
  }

  .coffee {
    display: none;
  }

  .social-block__icon div {
    height: auto;
    width: 48%;
    text-align: left;
    display: inline-block !important;
  }

  .social-block__icon {
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 50px;
  }

  .info-company {
    gap: 16px;
  }

  .info-company__info-title-img {
    gap: 4px;
    flex-wrap: wrap;
  }

  .info-company__info-title {
    width: 100%;
  }

  .info-company__info-text {
    font-size: 13px;
    line-height: 24px;
  }

  .info-company__info-block {
    margin-bottom: 26px;
  }

  .footer__info-text {
    margin-bottom: 40px;
  }

  .footer__btn-container {
    margin-bottom: 74px;
    flex-wrap: wrap;
  }

  .footer__copy {
    margin-bottom: 0;
  }

  .content-time {
    flex-wrap: wrap;
    gap: 25px;
  }

  .header-fixed__info {
    flex-wrap: wrap;
    width: 155px;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  .social-block__8 {
    text-align: right !important;
  }

  .social-block__6 {
    text-align: right !important;
  }

  .social-block__4 {
    text-align: right !important;
  }

  .social-block__2 {
    text-align: right !important;
  }

  .social-block__icon {
    display: block;
  }


}

@media( min-width: 480px ){
  .header-fixed .logo-mob {
    display: none;
  }
  .pc-none-logo-pc{
    display: none;
  }
}

@media( max-width: 480px ){


  .header-fixed__logo {
    display: none;
  }

  .common-container-header {
    padding: 0px 15px;
  }

  .header-fixed .logo-mob {
    width: 50px;
    margin: 0;
    height: 50px;
  }

  .content-time__element {
    max-width: 130px;
  }



}







