*,
*::before,
*::after {
	box-sizing: border-box;
}

img {
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
}

figure {
	display: block;
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;
}


